import { Modal, Popconfirm, Popover, Switch } from "antd";
import LoaderMu from "components/common/LoaderMu";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { nameBasedProtectedRoutes } from "router/protected";
import { changePropertyStatus } from "services/propertyService";
import { FaRegEdit, FaRegCommentDots } from "react-icons/fa";
import { markFeaturedProperty, toggleExactLocationApi } from "services/propertyService";
import { Link } from "react-router-dom";
import Cookies from "universal-cookie";

export const ActionRowTemplate = ({ row, getPropertyData, setRowData, setCommentModalOpen }) => {
	const { t } = useTranslation()
	const [loading, setLoading] = useState(false)
	const userData = useSelector((state) => state?.user?.userData)
	const [showLocation, setShowLocation] = useState(row?.hideExactLocation ?? false)
	const [modalOpen, setModalOpen] = useState(false)
	const [isFeatured, setIsFeatured] = useState(row?.isFeatured ?? false)

	const { i18n } = useTranslation()
	const cookies = new Cookies()

	const currentLanguage = i18n?.language ?? cookies.get("langKey") ?? 'en'


	// HANDLE PROPERTY STATUS UPDATE
	const handlePropertyStatus = (id, status) => {
		setLoading(true)
		changePropertyStatus(id, status).then((response) => {
			toast.success(response?.message ?? "Status successfully updated")
			getPropertyData()
			handleModalClose()
			setLoading(false)
		}).catch((error) => {
			setLoading(false)
			toast.error(error?.response?.data?.error_description
				? error?.response?.data?.error_description
				: error?.response?.data?.message ?? error?.message)
		})
	}

	// HANDLE EXACT LOCATION TOGGLE
	const handleLocationShowToggle = (value) => {
		setLoading(true)
		toggleExactLocationApi(row?.id, value).then((response) => {
			setShowLocation(!showLocation)
			handleModalClose()
			toast.success(response?.message)
			setLoading(false)
		}).catch((error) => {
			setLoading(false)
			toast.error(
				error.response.data.error_description
					? error.response.data.error_description
					: error.response.data?.message ?? error?.message
			)
		})
	}
	// HANDLE FEATURE PROPERTY MARK TOGGLE
	const handleFeaturedMark = (value) => {
		setLoading(true)
		markFeaturedProperty(row?.id, value).then((response) => {
			setIsFeatured(!isFeatured)
			handleModalClose()
			toast.success(response?.message)
			setLoading(false)
		}).catch((error) => {
			setLoading(false)
			toast.error(
				error.response.data.error_description
					? error.response.data.error_description
					: error.response.data?.message ?? error?.message
			)
		})
	}

	// REDIRECT ON EDIT
	let redirectURL = `${nameBasedProtectedRoutes?.propertyEdit?.path}/${row?.id}`

	// REDIRECT ON BASIS OF STEP COMPLETED
	if (row?.status === 'DRAFT') {
		switch (row?.step) {
			case 'IDENTIFICATION':
				redirectURL = `${nameBasedProtectedRoutes?.propertyAdd?.path}?tab=${2}&id=${row?.id}`
				break;
			case 'DESCRIPTION':
				redirectURL = `${nameBasedProtectedRoutes?.propertyAdd?.path}?tab=${3}&id=${row?.id}`
				break;
			case 'DETAILS':
				redirectURL = `${nameBasedProtectedRoutes?.propertyAdd?.path}?tab=${4}&id=${row?.id}`
				break;
			case 'AMENITIES':
				redirectURL = `${nameBasedProtectedRoutes?.propertyAdd?.path}?tab=${1}&id=${row?.id}`
				break;
			default:
				redirectURL = `${nameBasedProtectedRoutes?.propertyAdd?.path}?tab=${1}&id=${row?.id}`
				break;
		}
	}

	if (row?.status === 'PUBLISHED') {
		switch (row?.step) {
			case 'IDENTIFICATION':
				redirectURL = `${nameBasedProtectedRoutes?.propertyEdit?.path}?tab=${2}&id=${row?.id}`
				break;
			case 'DESCRIPTION':
				redirectURL = `${nameBasedProtectedRoutes?.propertyEdit?.path}?tab=${3}&id=${row?.id}`
				break;
			case 'DETAILS':
				redirectURL = `${nameBasedProtectedRoutes?.propertyEdit?.path}?tab=${4}&id=${row?.id}`
				break;
			case 'AMENITIES':
				redirectURL = `${nameBasedProtectedRoutes?.propertyEdit?.path}?tab=${1}&id=${row?.id}`
				break;
			default:
				redirectURL = `${nameBasedProtectedRoutes?.propertyEdit?.path}?tab=${1}&id=${row?.id}`
				break;
		}
	}

	// HANDLE COMMENT MODAL CLICK
	const handleCommentClick = (row) => {
		setRowData(row)
		setCommentModalOpen(true)
	}
	const renderStatus = () => {


		const isPartialAgent = userData?.isPartialAgent ?? false

		if (isPartialAgent) {
			switch (row?.status) {
				case "PUBLISHED":
					return <div>
						<button className="btn ud-btn btn-white p10 me-2 " onClick={() => {
							handlePropertyStatus(row?.id, 'OFF_MARKET')
						}}>
							{t("OFF MARKET")}
						</button>
						<button className="btn ud-btn btn-white p10 me-2" onClick={() => {
							handlePropertyStatus(row?.id, 'SOLD')
						}}>
							{t("SOLD")}
						</button>
						<button className="btn ud-btn btn-white p10 me-2" onClick={() => {
							handlePropertyStatus(row?.id, 'EXPIRED')
						}} >
							{t("EXPIRED")}
						</button>
					</div>

				case "OFF_MARKET":
					return <div>
						<button className="btn ud-btn btn-white p10 me-2" onClick={() => {
							handlePropertyStatus(row?.id, 'SOLD')
						}}>
							{t("SOLD")}
						</button>
						<button className="btn ud-btn btn-white p10 me-2" onClick={() => {
							handlePropertyStatus(row?.id, 'EXPIRED')
						}}>
							{t("EXPIRED")}
						</button>
					</div>
				default: return ''
			}
		} else {
			switch (row?.status) {
				case "PENDING":
					return <div>
						<button className="btn ud-btn btn-white p10 me-2" onClick={() => {
							handlePropertyStatus(row?.id, 'PUBLISHED')
						}}>
							{t("PUBLISH")}
						</button>
					</div>

				case "PUBLISHED":
					return <div>
						<button className="btn ud-btn btn-white p10 me-2" onClick={() => {
							handlePropertyStatus(row?.id, 'OFF_MARKET')
						}}>
							{t("OFF MARKET")}
						</button>
						<button className="btn ud-btn btn-white p10 me-2" onClick={() => {
							handlePropertyStatus(row?.id, 'SOLD')
						}}>
							{t("SOLD")}
						</button>
						<button className="btn ud-btn btn-white p10 me-2" onClick={() => {
							handlePropertyStatus(row?.id, 'EXPIRED')
						}} >
							{t("EXPIRED")}
						</button>
					</div>
				case "OFF_MARKET":
					return <div>
						<button className="btn ud-btn btn-white p10 me-2" onClick={() => {
							handlePropertyStatus(row?.id, 'PUBLISHED')
						}}>
							{t("PUBLISH")}
						</button>
						<button className="btn ud-btn btn-white p10 me-2" onClick={() => {
							handlePropertyStatus(row?.id, 'SOLD')
						}}>
							{t("SOLD")}
						</button>
						<button className="btn ud-btn btn-white p10 me-2" onClick={() => {
							handlePropertyStatus(row?.id, 'EXPIRED')
						}}>
							{t("EXPIRED")}
						</button>
					</div>
				default: return ''
			}
		}
	}

	const handleModalClose = () => {
		setModalOpen(false)
	}
	const handleModalOpen = () => {
		setModalOpen(true)
	}

	return (
		<div className="d-flex justify-content-end">
			<LoaderMu loading={loading} />
			{
				row?.status !== 'EXPIRED' && row?.status !== 'SOLD' ?

					<button className="btn" onClick={handleModalOpen}>
						<span className="fas fa-ellipsis-v" />
					</button> : ""
			}

			{/* {
				row?.status !== 'EXPIRED' && row?.status !== 'SOLD' ?
					<div className="text-center text-lg-end header_right_widgets">
						<ul className="mb0 d-flex justify-content-center justify-content-sm-end p-0">
							<li className="user_setting">
								<div className="dropdown">
									<Link to="#" className="btn px5" data-bs-toggle="dropdown">
										<span className="fas fa-ellipsis-v" />
									</Link>
									<div className="dropdown-menu w-auto actions">
										<div className="user_setting_content">
											<p className="fz14 fw400 ff-heading text-gray mb-1 bb1">
												{t("ACTIONS")}
											</p>
											{
												row?.status !== "DRAFT" ?
													<button className="btn dropdown-item p10 m5" onClick={() => handleCommentClick(row)}> {t("COMMENT")}</button>
													: ""
											}
											<Link className="dropdown-item text-uppercase p10 lh0 m5" to={redirectURL}>
												{t("EDIT")}
											</Link>
											{renderStatus()}
										</div>
									</div>
								</div>
							</li>
						</ul>
					</div> : ""
			} */}

			<Modal
				title={t("ACTIONS")}
				open={modalOpen}
				centered
				onCancel={handleModalClose}
				onOk={handleModalClose}
				footer={false}
				className={`${currentLanguage === 'ar' ? 'direction-rtl' : ''}`}
			>
				<div className="user_setting_content">
					<div className="d-flex align-items-center mb-3">
						<Link className="btn btn-dark ud-btn me-2" to={redirectURL}>
							<FaRegEdit className="mb-1" /> {t("EDIT")}
						</Link>

						{
							row?.status !== "DRAFT" ?
								<button className="btn btn-dark ud-btn" onClick={() => handleCommentClick(row)}> <FaRegCommentDots className="me-1" /> {t("COMMENT")}</button>
								: ""
						}
					</div>

					{renderStatus()}
					<div className="mt-3">
						{
							!showLocation ? <Popconfirm
								placement="topLeft"
								title={t("ARE YOU SURE YOU WANT TO HIDE LOCATION")}
								description={t("HIDE LOCATION TEXT")}
								okText={t("YES")}
								cancelText={("NO")}
								onConfirm={() => handleLocationShowToggle(true)}
							>
								<button className="btn btn-dark ud-btn me-2">
									{t("HIDE EXACT LOCATION")}
								</button>
							</Popconfirm> : <button className="btn btn-dark ud-btn me-2"
								onClick={() => handleLocationShowToggle(false)}>
								{t("SHOW EXACT LOCATION")}
							</button>
						}



						{
							userData?.authorities?.includes('ROLE_AGENCY_ADMIN') && row?.status === "PUBLISHED" ?

								<Switch className="fw-bold" disabled={loading} checked={isFeatured} checkedChildren={t("FEATURED")} unCheckedChildren={t("FEATURED")} onChange={handleFeaturedMark} />
								: ""
						}
						{/* <Switch className="ms-2 fw-bold" disabled={loading} checked={showLocation} checkedChildren={t("HIDE EXACT LOCATION")} unCheckedChildren={t("SHOW EXACT LOCATION")} onChange={handleLocationShowToggle} /> */}


					</div>
				</div>
			</Modal>
		</div>
	);
};