import AgencyDatePicker from 'components/common/AgencyDatePicker';
import AgencyInputText from 'components/common/AgencyInputText';
import AgencySelect from 'components/common/AgencySelect';
import LoaderMu from 'components/common/LoaderMu';
import MapMarkerDrag from 'components/map/MapMarkerDrag';
import { Form, Formik } from 'formik';
import { FloorNoDropdownList } from 'jsonData/StaticData';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { nameBasedProtectedRoutes } from 'router/protected';
import { DirectorateListApi, GovernorateListApi, PropertyInfoApi, PropertyListApi, SaveUCIdentificationData, VillageListApi, EditIdentificationData } from 'services/propertyService';
import Cookies from 'universal-cookie';
import { underConstructPropValidationSchema } from 'validations/ValidationSchema';

const UnderConstruction = ({ property_id, propertyData, getPropertyData , isEdit}) => {

	const { i18n } = useTranslation();

	const cookies = new Cookies()
	const langKey = i18n?.language ?? cookies.get("langKey") ?? 'en'

	const { t } = useTranslation();
	const [loading, setLoading] = useState(false)

	const navigate = useNavigate()
	// FORM INITIAL VALUES
	// MAP MARKER COORDINATES
	const [markerCoordinates, setMarkerCoordinates] = useState({ lat: 30.5852, lng: 36.2384 })
	const [initialValues, setInitialValues] = useState({
		// IDENTIFICATION
		governorate: "",
		directorate: "",
		village: "",
		basin: "",
		district: "",
		availableFrom: "",
		longitude: markerCoordinates?.lng,
		latitude: markerCoordinates?.lat,
		propertyOfferingType: '',
		propertyCategory: "",
		propertyTypeId: "",
		priceInJOD: "",
		agencyRefNo: "",
		listing_date: moment().format('YYYY-MM-DD'),
		amakenId: ""
	})

	// INFO STATES
	const [propertyInfo, setPropertyInfo] = useState({})
	// SELECTED PROPERTY CATEGORY
	const [selectedPropCategory, setSelectedPropertyCategory] = useState('')
	const [selectedPropType, setSelectedPropType] = useState('')
	const [propertyTypeList, setPropertyTypeList] = useState([])

	// STATES FOR DROPDOWN LIST
	const [governorateList, setGovernorateList] = useState([])
	const [directorateList, setDirectorateList] = useState([])
	const [villageList, setVillageList] = useState([])
	// STATES FOR DROPDOWN SELECTION
	const [selectedGovernorate, setSelectedGovernorate] = useState()
	const [selectedDirectorate, setSelectedDirectorate] = useState()
	const [selectedVillage, setSelectedVillage] = useState()
	// STATE FOR DROPDOWN SEARCH
	const [governorateSearch, setGovernorateSearch] = useState("")
	const [directorateSearch, setDirectorateSearch] = useState("")
	const [villageSearch, setVillageSearch] = useState("")



	// FOR VILLAGE SCROLL PAGINATION
	const [page, setPage] = useState(0)
	const [totalCount, setTotalCount] = useState(0)
	// FLOOR NUMBER DROPDOWN LIST
	const floorList = FloorNoDropdownList?.map((floor) => ({ label: floor?.label[langKey], value: floor?.value }))

	// FUNCTION FOR FETCH GOVERNORATE LIST
	const getGovernorateList = () => {
		setLoading(true)
		let params = {
			search: governorateSearch ?? '',
			size: 50,
		}
		GovernorateListApi(params).then(response => {
			if (response?.data && response?.data?.length > 0) {
				let governorates = []
				response?.data?.forEach((governorate) => {
					governorates.push({ label: governorate?.governorate, value: governorate?.id })
				})
				setGovernorateList(governorates)
			}
			setLoading(false)
		}).catch((error) => {
			setLoading(false)
			toast.error(
				error?.response?.data?.error_description
					? error?.response?.data?.error_description
					: error?.response?.data?.message ?? error?.message
			);
		})
	}
	// GET GOVERNORATE LIST
	useEffect(() => {
		getGovernorateList()
	}, [governorateSearch])

	// FUNCTION FOR FETCH DIRECTORATE LIST
	const getDirectorateList = (isScroll) => {
		setLoading(true)
		let params = {
			search: directorateSearch ?? "",
			size: 50
		}
		let governorateId = parseInt(selectedGovernorate)
		DirectorateListApi(governorateId, params).then(response => {
			if (response?.data && response?.data?.length > 0) {
				let directorates = []
				response?.data?.forEach((directorate) => {
					directorates.push({ label: directorate?.directorate, value: directorate?.id })
				})
				setDirectorateList(directorates)
			}
			// setTotalRowCount(response?.headers["x-total-count"])
			setLoading(false)
		}).catch((error) => {
			setLoading(false)
			toast.error(error.response.data.error_description
				? error.response.data.error_description
				: error.response.data?.message)
		})
	}
	// GET DIRECTORATE LIST
	useEffect(() => {
		if (selectedGovernorate && selectedGovernorate !== "") {
			getDirectorateList(false)
		}
	}, [selectedGovernorate, directorateSearch])
	// FUNCTION FOR FETCH VILLAGE LIST
	const getVillageList = () => {
		setLoading(true)
		let params = {
			search: villageSearch ?? '',
			size: 25,
			page: page
		}
		let directorateId = parseInt(selectedDirectorate)
		VillageListApi(directorateId, params).then(response => {
			if (response?.data && response?.data?.length > 0) {
				let villages = []
				response?.data?.forEach((village) => {
					villages.push({ label: village?.village, value: village?.id, villageCode: village?.villageCode })
				})
				if (page === 0) {
					setVillageList(villages)
				} else {
					// Filter out duplicate options before updating the state
					const uniqueNewOptions = new Set([...villageList, ...villages])
					// Convert the Set back to an array (if needed)
					const combinedArray = Array.from(uniqueNewOptions);
					setVillageList(combinedArray)
				}
			}
			setTotalCount(parseInt(response?.headers["x-total-count"] ?? 0))
			setLoading(false)
		}).catch((error) => {
			setLoading(false)
			toast.error(error?.response?.data?.error_description
				? error?.response?.data?.error_description
				: error?.response?.data?.message)
		})
	}
	// GET VILLAGE LIST
	useEffect(() => {
		if (selectedDirectorate && selectedDirectorate !== "") {
			getVillageList()
		}
	}, [selectedDirectorate, villageSearch, page])




	const handleVillageScroll = (e) => {
		const { target } = e;
		const lastOption = target.querySelector('.ant-select-item:last-child');
		if (totalCount > villageList?.length) {
			if (lastOption && lastOption.getBoundingClientRect().bottom <= target.getBoundingClientRect().bottom) {
				// User has scrolled to the last option and total count is not available
				setPage((prevPage) => prevPage + 1);
			}
		}
	}


	// FUNCTION FOR FETCH PROPERTY INFO
	const getPropertyInfo = () => {
		setLoading(true)
		PropertyInfoApi().then(response => {
			const offeringType = Object.keys(response?.propertyOfferingType ?? {}).map((key) => {
				return { value: key, label: response?.propertyOfferingType[key] }
			}
			);
			const propertyCategory = Object.keys(response?.propertyCategory ?? {}).map((key) => {
				return { value: key, label: response?.propertyCategory[key] }
			}
			);
			setPropertyInfo({ offeringType, propertyCategory })
			setInitialValues((prev) => ({ ...prev, amakenId: response?.amakenId }))
			setLoading(false)
		}).catch((error) => {
			setLoading(false)
			toast.error(error.response.data.error_description
				? error.response.data.error_description
				: error.response.data?.message)
		})
	}

	// FUNCTION FOR FETCH PROPERTY TYPE LIST
	const getPropertyType = () => {
		setLoading(true)
		PropertyListApi().then(response => {
			const propTypes = response?.filter((element) => (element?.propertyCategory === selectedPropCategory && element?.status === true))
			setPropertyTypeList(propTypes)
			const selectedPropertyType = propTypes.find((element) => element?.id === propertyData?.propertyTypeId)
			if (selectedPropertyType) {
				setSelectedPropType(propertyData?.propertyTypeId)
			} else {
				setSelectedPropType('')
			}
			setLoading(false)
		}).catch((error) => {
			setLoading(false)
			toast.error(error.response.data.error_description
				? error.response.data.error_description
				: error.response.data?.message)
		})
	}

	useEffect(() => {
		getPropertyInfo()
	}, [])

	useEffect(() => {
		getPropertyType()
	}, [selectedPropCategory])

	// HANDLE FORM SUBMIT
	const handleSubmit = (values) => {
		setLoading(true	)
		let editFormData = {};
		let formData = {};
		if(isEdit){
			editFormData = {
				id: isEdit ? property_id : propertyData?.id ?? null,
				propertyOfferingType: values?.propertyOfferingType,
				propertyCategory: values?.propertyCategory,
				propertyTypeId: values?.propertyTypeId,
				priceInJOD: values?.priceInJOD,
				listingDate: moment().format('YYYY-MM-DD'),
				agencyRefNo: values?.agencyRefNo
			};
		}else{
			formData = {
				id: propertyData?.id ?? null,
				governorateId: values?.governorate,
				directorateId: values?.directorate,
				villageId: values?.village,
				villageCode: selectedVillage?.villageCode,
				basin: values?.basin,
				district: values?.district,
				availableFrom: values?.availableFrom,
				longitudeWgs84: markerCoordinates?.lng,
				latitudeWgs84: markerCoordinates?.lat,
				buildingNumber: values?.buildingNumber,
				floorNumber: values?.floorNumber,
				apartmentId: values?.apartmentId,
				propertyOfferingType: values?.propertyOfferingType,
				propertyCategory: values?.propertyCategory,
				propertyTypeId: values?.propertyTypeId,
				priceInJOD: values?.priceInJOD,
				listingDate: moment().format('YYYY-MM-DD'),
				amakenId: values?.amakenId
			}
		}
		
		//ADD AGENCY REFERENCE NUMBER
		if (values?.agencyRefNo) {
			formData.agencyRefNo = values?.agencyRefNo
		}
		// CALLING API FOR SAVE IDENTIFICATION
		if(isEdit){
			EditIdentificationData(editFormData).then((response) => {
				navigate(`${nameBasedProtectedRoutes?.propertyEdit?.path}?tab=${2}&id=${response?.id}`, { replace: true })
				toast.success("Identification data updated.")
			}).catch((error) => {
				toast.error(error.response.data.error_description
					? error.response.data.error_description
					: error.response.data?.message)
			}).finally(() => {
				setLoading(false)
			})
		}else{
			SaveUCIdentificationData(formData).then((response) => {
				navigate(`${nameBasedProtectedRoutes?.propertyAdd?.path}?tab=${2}&id=${response?.id}`, { replace: true })
				toast.success("Identification data saved.")
			}).catch((error) => {
				toast.error(error.response.data.error_description
					? error.response.data.error_description
					: error.response.data?.message)
			}).finally(() => {
				setLoading(false)
			})
		}
		
	}

	// SET FORM INITIAL VALUE IN CASE OF EDIT FORM
	useEffect(() => {
		if (property_id)
			setInitialValues({
				governorate: propertyData?.governorateId,
				directorate: propertyData?.directorateId,
				village: propertyData?.villageId,
				basin: propertyData?.basin,
				district: propertyData?.district,
				availableFrom: propertyData?.availableFrom ? new Date(propertyData?.availableFrom) : "",
				longitude: propertyData?.coordinateWgs84?.x,
				latitude: propertyData?.coordinateWgs84?.y,
				buildingNumber: propertyData?.buildingNumber,
				floorNumber: propertyData?.floorNumber,
				apartmentId: propertyData?.apartmentId,
				propertyOfferingType: propertyData?.propertyOfferingType,
				propertyCategory: propertyData?.propertyCategory,
				propertyTypeId: propertyData?.propertyTypeId,
				priceInJOD: propertyData?.priceInJod,
				listing_date: moment().format('YYYY-MM-DD'),
				amakenId: propertyData?.amakenId,
				agencyRefNo: propertyData?.agencyRefNo ?? "",
			})

		if (propertyData?.governorateId) {
			setSelectedGovernorate(propertyData?.governorateId)
		} else {
			setSelectedGovernorate('')
		}
		if (propertyData?.governorateId) {
			setSelectedDirectorate(propertyData?.directorateId)
		} else {
			setSelectedDirectorate('')
		}
		if (propertyData?.villageId) {
			setSelectedVillage({ villageId: propertyData?.villageId, villageCode: propertyData?.village?.villageCode })
		} else {
			setSelectedVillage({})
		}
		if (propertyData?.propertyCategory) {
			setSelectedPropertyCategory(propertyData?.propertyCategory)
		}
		if (propertyData?.coordinateWgs84) {
			setMarkerCoordinates({ lng: propertyData?.coordinateWgs84?.x, lat: propertyData?.coordinateWgs84?.y })
		}
	}, [propertyData])

	return (
		<>
			<LoaderMu loading={loading} />
			<Formik
				validationSchema={underConstructPropValidationSchema}
				initialValues={initialValues}
				enableReinitialize={true}
				onSubmit={(values, actions) => {
					actions.setSubmitting(false);
					handleSubmit(values)
				}}>
				{({
					handleSubmit,
					handleChange,
					handleBlur,
					values,
					setFieldValue,
					setFieldError,
					touched,
					isValid,
					errors,
				}) => (
					<Form>
						<div className="form-style1">
							<div className="row"  >
								<div className="col-sm-6 col-xl-4">
									<AgencySelect
										disabled={isEdit}
										showSearch={true}
										filterOption={false}
										label={t("GOVERNORATE")}
										id="governorate"
										placeholder={t("SELECT")}
										name="governorate"
										enableScrollPagination={true}
										handleChange={(value) => {
											setFieldValue("governorate", value);
											setSelectedGovernorate(value)
											setSelectedDirectorate("")
											setSelectedVillage("")
										}}
										handleSearch={(value) => {
											setGovernorateSearch(value)
										}}
										handleBlur={handleBlur}
										value={selectedGovernorate ?? undefined}
										className={`selectpicker form-control ${touched?.governorate &&
											errors?.governorate
											? "error"
											: ""
											}`}
										options={governorateList}
									/>
								</div>
								<div className="col-sm-6 col-xl-4">
									<AgencySelect
										disabled={isEdit}
										showSearch={true}
										filterOption={false}
										label={t("DIRECTORATE")}
										id="directorate"
										placeholder={t("SELECT")}
										name="directorate"
										handleChange={(value) => {
											setFieldValue("directorate", value);
											setSelectedDirectorate(value)
											setVillageList([])
											setSelectedVillage("")
										}}
										handleSearch={(value) => {
											setDirectorateSearch(value)
										}}
										handleBlur={handleBlur}
										value={selectedDirectorate ?? undefined}
										className={`selectpicker form-control ${touched?.directorate &&
											errors?.directorate
											? "error"
											: ""
											}`}
										options={directorateList ?? []}
									/>
								</div>
								<div className="col-sm-6 col-xl-4">
									<AgencySelect
										disabled={isEdit}
										filterOption={false}
										showSearch={true}
										label={t('VILLAGE')}
										id="village"
										placeholder={t("SELECT")}
										name="village"
										handleChange={(value, option) => {
											setFieldValue("village", value);
											setSelectedVillage({ villageId: option?.value, villageCode: option?.villageCode })
										}}
										handleSearch={(value) => {
											setPage(0)
											setVillageSearch(value)
										}}
										handleScroll={(e) => handleVillageScroll(e)}
										handleBlur={handleBlur}
										value={selectedVillage?.villageId}
										className={`selectpicker form-control ${touched?.village && errors?.village
											? "error"
											: ""
											}`}
										options={villageList ?? []}
									/>
								</div>
							</div>
							<div className="row">
								<div className="col-sm-6 col-xl-4">
									<AgencyInputText
										readOnly={isEdit}
										type="number"
										label={t('BASIN')}
										id="basin"
										placeholder={t('BASIN')}
										name="basin"
										handleChange={(event) => {
											setFieldValue("basin", event?.target?.value)
										}}
										handleBlur={handleBlur}
										value={values?.basin}
										className={`form-control ${touched?.basin && errors?.basin
											? "error"
											: ""
											}`}
									/>
								</div>
								<div className="col-sm-6 col-xl-4">
									<AgencyInputText
										readOnly={isEdit}
										type="number"
										label={t("DISTRICT")}
										id="district"
										placeholder={t("DISTRICT")}
										name="district"
										// handleChange={handleChange}
										handleChange={(event) => {
											setFieldValue("district", event?.target?.value)
										}}
										handleBlur={handleBlur}
										value={values?.district}
										className={`form-control ${touched?.district && errors?.district
											? "error"
											: ""
											}`}
									/>
								</div>
								<div className="col-sm-6 col-xl-4">
									<AgencyDatePicker
										label={t("AVAILABLE FROM")}
										placeholder={t("SELECT DATE")}
										id={"availableFrom"}
										name={"availableFrom"}
										minDate={new Date()}
										handleChange={(event) => {
											setFieldValue("availableFrom", event?.value)
										}}
										className={"custom-datepicker"}
										value={values?.availableFrom}
									/>
								</div>
							</div>
							{/* MAP COMPONENT */}
							<div className="row">
								<div className="col-md-12 mb30">
									<MapMarkerDrag setMarkerCoordinates={setMarkerCoordinates} markerCoordinates={markerCoordinates} />
								</div>
								<div className="col-sm-6 col-xl-4">
									<AgencyInputText
										readOnly={true}
										type="number"
										label={t("LONGITUDE")}
										id="longitude"
										placeholder={t("LONGITUDE")}
										name="longitude"
										handleChange={handleChange}
										handleBlur={handleBlur}
										value={markerCoordinates?.lng ?? 0}
										className={`form-control ${touched?.longitude && errors?.longitude
											? "error"
											: ""
											}`}
									/>
								</div>
								<div className="col-sm-6 col-xl-4">
									<AgencyInputText
										readOnly={true}
										type="number"
										label={t("LATITUDE")}
										id="latitude"
										placeholder={t("LATITUDE")}
										name="latitude"
										handleChange={handleChange}
										handleBlur={handleBlur}
										value={markerCoordinates?.lat ?? 0.0}
										className={`form-control ${touched?.latitude && errors?.latitude
											? "error"
											: ""
											}`}
									/>
								</div>
								<div className="col-sm-6 col-xl-4">
									<AgencyInputText
										type="number"
										label={t("PRICE IN JOD")}
										id="priceInJOD"
										placeholder={t("PRICE IN JOD")}
										name="priceInJOD"
										handleChange={handleChange}
										handleBlur={handleBlur}
										value={values?.priceInJOD}
										className={`form-control ${touched?.priceInJOD && errors?.priceInJOD
											? "error"
											: ""
											}`}
									/>
								</div>
								{/* <div className="col-sm-6 col-xl-4">
									<AgencyInputText
										type="number"
										label={t("BUILDING NUMBER")}
										id="buildingNumber"
										placeholder={t("BUILDING NUMBER")}
										name="buildingNumber"
										handleChange={handleChange}
										handleBlur={handleBlur}
										value={values?.buildingNumber}
										className={`form-control ${touched?.buildingNumber &&
											errors?.buildingNumber
											? "error"
											: ""
											}`}
									/>
								</div> */}
							</div>
							<div className="row">
								{/* <div className="col-sm-6 col-xl-4">
									<AgencySelect
										label={t("FLOOR NUMBER")}
										id="floorNumber"
										placeholder={t("FLOOR NUMBER")}
										name="floorNumber"
										handleChange={(value) => {
											setFieldValue('floorNumber', value)
										}}
										handleBlur={handleBlur}
										value={values?.floorNumber}
										className={`selectpicker form-control ${touched?.floorNumber &&
											errors?.floorNumber
											? "error"
											: ""
											}`}
										options={floorList ? [{ value: '', label: 'Select' }, ...floorList] : []}
									/>
								</div>
								<div className="col-sm-6 col-xl-4">
									<AgencyInputText
										type="string"
										label={t("APARTMENT ID")}
										id="apartmentId"
										placeholder={t("APARTMENT ID")}
										name="apartmentId"
										handleChange={handleChange}
										handleBlur={handleBlur}
										value={values?.apartmentId}
										className={`form-control ${touched?.apartmentId &&
											errors?.apartmentId
											? "error"
											: ""
											}`}
									/>
								</div> */}
								{/* <div className="col-sm-6 col-xl-4">
									<AgencyInputText
										type="number"
										label={t("PRICE IN JOD")}
										id="priceInJOD"
										placeholder={t("PRICE IN JOD")}
										name="priceInJOD"
										handleChange={handleChange}
										handleBlur={handleBlur}
										value={values?.priceInJOD}
										className={`form-control ${touched?.priceInJOD && errors?.priceInJOD
											? "error"
											: ""
											}`}
									/>
								</div> */}
							</div>
							<div className="row">
								<div className="col-sm-6 col-xl-4">
									<AgencySelect
										label={t('OFFERING TYPE')}
										id="propertyOfferingType"
										placeholder={t("SELECT")}
										name="propertyOfferingType"
										handleChange={(value) =>
											setFieldValue("propertyOfferingType", value)
										}
										handleBlur={handleBlur}
										value={values?.propertyOfferingType}
										className={`selectpicker form-control ${touched?.propertyOfferingType &&
											errors?.propertyOfferingType
											? "error"
											: ""
											}`}
										options={propertyInfo?.offeringType ?? []}
									/>
								</div>
								<div className="col-sm-6 col-xl-4">
									<AgencySelect
										label={t("PROPERTY CATEGORY")}
										id="propertyCategory"
										placeholder={t("SELECT")}
										name="propertyCategory"
										handleChange={(value) => {
											setFieldValue("propertyCategory", value);
											setSelectedPropertyCategory(value)
											// setFieldValue("propertyTypeId", '');
										}}
										handleBlur={handleBlur}
										value={values?.propertyCategory}
										className={`selectpicker form-control ${touched?.propertyCategory &&
											errors?.propertyCategory
											? "error"
											: ""
											}`}
										options={propertyInfo?.propertyCategory ?? []}
									/>
								</div>
								<div className="col-sm-6 col-xl-4">
									<AgencySelect
										label={t("PROPERTY TYPE")}
										id="propertyTypeId"
										placeholder={t("SELECT")}
										name="propertyTypeId"
										handleChange={(value) => {
											setFieldValue("propertyTypeId", value);
											setSelectedPropType(value)
										}}
										handleBlur={handleBlur}
										value={selectedPropType ?? values?.propertyTypeId}
										className={`selectpicker form-control ${touched?.propertyTypeId &&
											errors?.propertyTypeId
											? "error"
											: ""
											}`}
										options={propertyTypeList && propertyTypeList?.length > 0 ?
											propertyTypeList.map((type) => (
												{ value: type?.id, label: type?.propertyType }
											)) : []
										}
									/>
								</div>
							</div>
							<div className="row">
								<div className="col-sm-6 col-xl-4">
									{/* <AgencyDatePicker className="custom-datepicker"/> */}
									<AgencyInputText
										label={t("AGENCY REFERENCE")}
										id="agencyRefNo"
										placeholder={t("AGENCY REFERENCE")}
										name="agencyRefNo"
										handleChange={handleChange}
										handleBlur={handleBlur}
										value={values?.agencyRefNo}
										className={`form-control ${touched?.agencyRefNo &&
											errors?.agencyRefNo
											? "error"
											: ""
											}`}
									/>
								</div>
								<div className="col-sm-6 col-xl-4">
									{/* <AgencyDatePicker className="custom-datepicker"/> */}
									<AgencyInputText
										readOnly={true}
										label={t("LISTING DATE")}
										id="listing_date"
										placeholder={t("LISTING DATE")}
										name="listing_date"
										handleChange={handleChange}
										handleBlur={handleBlur}
										value={values?.listing_date}
										className={`form-control ${touched?.listing_date &&
											errors?.listing_date
											? "error"
											: ""
											}`}
									/>
								</div>
								<div className="col-sm-6 col-xl-4">
									<AgencyInputText
										type="text"
										label={t("AMAKEN ID")}
										id="amakenId"
										placeholder={t("AMAKEN ID")}
										name="amakenId"
										handleChange={handleChange}
										handleBlur={handleBlur}
										value={values?.amakenId ?? ''}
										readOnly={true}
										className={`form-control ${touched?.amakenId && errors?.amakenId
											? "error"
											: ""
											}`}
									/>
								</div>
							</div>
							<div className="row">
								<div className="col-sm-12 mb-4">
									<div className="d-sm-flex justify-content-end align-items-center">
										<button
											disabled={loading ?? false}
											type="submit"
											className="ud-btn btn-dark"
											onClick={handleSubmit}
										>
											{t("NEXT STEP")}
											<i className="fal fa-arrow-right-long" />
										</button>
									</div>
								</div>
							</div>
						</div>
					</Form>)}
			</Formik>
		</>
	)
}

export default UnderConstruction